<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <div class="d-flex justify-content-between align-items-center px-1 py-50">
            <div class="col-3" >
              <h3 class="m-0" >{{ $t('Agrupaciones') }}</h3>
            </div>
            <div class="d-flex align-items-center" >
              <div>
                <b-link
                  :to="{ name: 'createSet' }"
                >
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mr-1 text-nowrap"
                  >
                    {{ $t('Crear nueva') }}
                  </b-button>
                </b-link>
              </div>
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchTerm"
                  type="text"
                  class="d-inline-block"
                  :placeholder="$t('Busqueda')"
                  @input="handleSearch"
                />
              </b-input-group>
            </div>
          </div>
          <b-table
            hover
            responsive
            :items="items"
            :fields="columns"
          >
            <template #cell(client)="data">
              <span v-if="data.item.client" >
                {{ data.item.client.name }}
              </span>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-link
                  :to="{ name: 'editSet', params: { id: data.item.id} }"
                  class="mr-1"
                >
                  <feather-icon icon="Edit2Icon" size="16" />
                </b-link>
                <span
                  class="text-danger cursor-pointer"
                  @click="deleteSet( data.item.id, data.item.name)"
                >
                  <feather-icon
                    icon="TrashIcon"
                  />
                </span>
              </span>
            </template>
          </b-table>
        </b-card>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <div class="d-flex align-items-center mb-0">
            <span class="text-nowrap ">
              {{ $t('PorPagina') }}:
            </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="ml-50 mr-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap"> {{ $t('Total') }}: {{ totalItems }}</span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BBadge, BPagination, BFormSelect, BButton, BTable, BRow, BCol, BCard, BLink, BInputGroup, BInputGroupPrepend, BFormInput, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import store from '@/store/index'
import { TimestampToFlatPickr } from '@/libs/helpers'
import { config } from '@/shared/app.config'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BTable,
    BPagination,
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BLink,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
  },
  data() {
    return {
      log: [],
      group: '',
      currentPage: 1,
      pageLength: 10,
      pages: ['10', '15', '25'],
      columns: [
        {
          label: this.$t('Nombre'),
          key: 'name',
        },
        {
          label: this.$t('Cliente'),
          key: 'client',
        },
        {
          label: this.$t('Acciones'),
          key: 'actions',
          class: 'text-right',
        },
      ],
      searchTerm: '',
    }
  },
  watch: {
    group() {
      this.chargeData()
    },
  },
  computed: {
    ...mapGetters({
      currentClient: 'clients/getCurrentClient',
      items: 'sets/getItems',
      totalItems: 'sets/getTotalItems',
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.chargeData()
  },
  methods: {
    ...mapActions({
      list: 'sets/getListSets',
      delete: 'sets/delete',
    }),
    chargeData() {
      const group = (this.group) ? this.group.id : ''
      const client = (this.currentClient) ? this.currentClient.id : ''
      this.list({
        client, page: this.currentPage, per_page: this.pageLength, search: this.searchTerm, group,
      })
    },
    dateColumn(date) {
      return TimestampToFlatPickr(date)
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    onSortChange(params) {
      this.currentPage = 1
      this.chargeData()
    },
    deleteSet(id, name) {
      this.$bvModal
        .msgBoxConfirm(this.$t('EliminarConfirm', {nombre: name}), {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: this.$t('Eliminar'),
          cancelTitle: this.$t('Cancelar'),
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.delete(id)
          }
        })
    },
  },
}
</script>
